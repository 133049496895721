import { useMemo } from "react";

import useUserHasPermission from "hooks/useUserHasPermission";
import useUserHasPro from "hooks/useUserHasPro";
import useUserIsPodcastOwner from "hooks/useUserIsPodcastOwner";

export default (podcastId) => {
  const hasProPermission = useUserHasPro();
  const hasOwnerProPermission = useUserHasPermission("view owned pro data");
  const isOwner = useUserIsPodcastOwner(podcastId);
  const hasPro = useMemo(
    () => hasProPermission || (hasOwnerProPermission && isOwner),
    [hasOwnerProPermission, hasProPermission, isOwner]
  );

  return hasPro;
};
