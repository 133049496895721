import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    width: "100%",
    height: "14.2rem",

    [ScreenSizes.lgAndAbove]: {
      height: "5rem",
    },
  },
};

const EntityFollowerListAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <>
      <div className={css(styles.container)} />
    </>
  );
};

const EntityFollowerListAsyncMemo = memo(EntityFollowerListAsync);

const EntityFollowerList = Loadable(() => import("./EntityFollowerList"));

function EntityFollowerListAsyncLoading(props) {
  return <EntityFollowerListAsyncMemo />;
}

const EntityFollowerListContainer = (props) => {
  return (
    <EntityFollowerList
      {...props}
      fallback={<EntityFollowerListAsyncLoading />}
    />
  );
};

export default EntityFollowerListContainer;
