import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faSlidersH } from "@fortawesome/pro-solid-svg-icons/faSlidersH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  titleContainer: {
    ...gStyles.avalonBold,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0 0 1rem",
    fontSize: "1rem",
    marginRight: ".875rem",
    width: "100%",

    [ScreenSizes.smAndAbove]: {
      marginRight: "1rem",
      fontSize: "1.125rem",
    },

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
      padding: "3rem 0 1.5rem",
      cursor: "auto",
    },
  },
  titleInner: {},
  title: {
    margin: 0,
    padding: 0,
    fontSize: "1em",
    fontWeight: "inherit",
    marginRight: "1rem",
  },
  link: {
    color: "#000",
    marginLeft: "auto",
    cursor: "pointer",
    fontSize: "1em",
    fontWeight: "inherit",

    [ScreenSizes.lgAndAbove]: {
      ...gStyles.fontRegular,
      fontSize: "0.875em",
      color: "#6E727B",
      cursor: "pointer",
      marginLeft: 0,
    },
  },
  inlineToggleContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "1rem",
    border: "none",

    ...getHoverQuery(
      {
        transform: "scale(1.1,1.1)",
      },
      ":hover .icon"
    ),
  },
  inlineToggleLabel: {
    ...gStyles.fontBold,
    transition: generateTransition({ target: "opacity", speed: "80ms" }),
    color: colours.bodyText,
    fontSize: ".75rem",
    marginRight: "0.75rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".625rem",
    },
  },
  inlineToggleLabelActive: {
    opacity: 0.5,
  },
  inlineToggleIcon: {
    color: colours.bodyText,
    transition: generateTransition({ target: "opacity", speed: "80ms" }),
  },
  inlineToggleIconActive: {
    opacity: 0.5,
  },
  inlineContent: {
    marginLeft: "auto",
    width: "100%",
    marginTop: "1rem",

    [ScreenSizes.lgAndAbove]: {
      width: "auto",
      marginTop: 0,
      maxHeight: "2.375rem",
    },
  },
  additionalContent: {
    display: "none",
    marginTop: "1rem",
    marginLeft: 0,
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      marginTop: "1rem",
      height: "2.375rem",
      maxHeight: "2.375rem",
    },
  },
  showingAdditionalContent: {
    display: "block",
  },
  count: {
    ...gStyles.fontMedium,
    color: colours.entitySidebarText,
    fontSize: ".75rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: ".875rem",
    },
  },
  mobileAdditionalToggle: {
    fontSize: ".875rem",
  },
};

const inSidebarStyles = {
  titleContainer: {
    paddingTop: 0,
    paddingBottom: "1rem",
    fontSize: "1rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1rem",
    },

    [ScreenSizes.lgAndAbove]: {
      paddingTop: "0",
      paddingBottom: "1rem",
      fontSize: "1rem",
    },
  },
  title: {
    [ScreenSizes.smAndAbove]: {
      fontSize: "1em",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1em",
      marginTop: 0,
    },
  },
};

const primaryStyles = {
  titleContainer: {
    fontSize: "1rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1.25rem",
    },

    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.5rem",
    },

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.75rem",
    },
  },
};

const noTopPaddingStyles = {
  titleContainer: {
    paddingLeft: 0,
  },
};

const withBorderStyles = {
  titleContainer: {
    borderBottom: "1px solid var(--color-neutral-d9)",
    marginBottom: "1rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "3rem 0 1rem",
      marginBottom: "1.5rem",
      minHeight: "6.875rem",
    },
  },
};

const withInlineStyles = {
  title: {
    height: "2.375rem",
    lineHeight: "2.375rem",
    marginTop: 0,
  },
};

const Title = (props) => {
  const {
    onTabChange,
    title: passedTitle,
    children,
    inlineContent,
    additionalContent,
    inlineToggleIcon,
    useToggle,
    toggleLabel,
    toggledLabel,
    toggleTitle,
    toggledTitle,
    count,
    showBorder,
    noTopPadding,
    TitleComponent,
    hiddenTitle,
    button,
    inSidebar,
    viewAllText,
    viewAllTitle,
    viewAllDataId,
    link,
    hideViewAll,
    primary,
    titleId,
    renderOnMount,
    passedRef,
    showViewAllRightIcon,
  } = props;

  const { styles } = useStyles(
    [
      baseStyles,
      showBorder && withBorderStyles,
      inSidebar && inSidebarStyles,
      noTopPadding && noTopPaddingStyles,
      primary && primaryStyles,
      inlineContent && withInlineStyles,
    ],
    props
  );

  const { isWindowSizeOrLess, isWindowSizeOrMore } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const title = passedTitle || children;

  const [showingAdditional, setShowingAdditional] = useState(false);

  const handleInlineToggleClick = useCallback(
    () => setShowingAdditional((prev) => !prev),
    []
  );

  const renderToggle = () => (
    <button
      className={css(styles.inlineToggleContainer)}
      onClick={handleInlineToggleClick}
      title={showingAdditional && toggledTitle ? toggledTitle : toggleTitle}
    >
      {toggleLabel && (
        <span
          className={css(
            styles.inlineToggleLabel,
            showingAdditional && styles.inlineToggleLabelActive
          )}
        >
          {showingAdditional && toggledLabel ? toggledLabel : toggleLabel}
        </span>
      )}
      <span
        className={`${css(
          styles.inlineToggleIcon,
          showingAdditional && styles.inlineToggleIconActive
        )} icon`}
      >
        {mobile ? (
          <span className={css(styles.mobileAdditionalToggle)}>
            {showingAdditional ? "Hide Filters" : "Show Filters"}
          </span>
        ) : (
          <FontAwesomeIcon icon={inlineToggleIcon || faSlidersH} />
        )}
      </span>
    </button>
  );

  const onLinkClick = useCallback(
    (e) => {
      if (e.type === "click" && onTabChange) {
        // Only left click
        onTabChange(e);
      }
    },
    [onTabChange]
  );

  const titleInner = useMemo(() => {
    if (link) {
      return (
        <Link
          data-id={viewAllDataId}
          to={link}
          className={css(styles.titleLink, styles.titleInner)}
          onClick={onLinkClick}
          title={viewAllTitle || viewAllText || `View All ${title}`}
        >
          {title}
        </Link>
      );
    }
    if (onTabChange) {
      return (
        <span onClick={onTabChange} className={css(styles.titleInner)}>
          {title}
        </span>
      );
    }

    return title;
  }, [
    link,
    onLinkClick,
    title,
    onTabChange,
    viewAllText,
    viewAllDataId,
    viewAllTitle,
    styles.titleLink,
    styles.titleInner,
  ]);

  return (
    <div
      className={css(styles.titleContainer)}
      onClick={isWindowSizeOrLess("medium") ? onTabChange : null}
      ref={passedRef}
    >
      <TitleComponent
        id={titleId}
        className={css(styles.title)}
        title={hiddenTitle || undefined}
      >
        {titleInner}
      </TitleComponent>
      <LazyLoadComponent renderOnMount={renderOnMount} height={16}>
        {count && <div className={css(styles.count)}>{count}</div>}
        {!hideViewAll && (link || onTabChange) && (
          <Link
            to={link || undefined}
            className={css(styles.link)}
            onClick={onLinkClick}
            title={viewAllTitle || viewAllText || `View All ${title}`}
            aria-label={viewAllText || "View All"}
          >
            {isWindowSizeOrLess("medium") && !showViewAllRightIcon ? (
              <FontAwesomeIcon icon={faAngleRight} />
            ) : (
              viewAllText || "View All"
            )}
          </Link>
        )}
        {button}
        {isWindowSizeOrLess("medium") && useToggle && renderToggle()}
        {inlineContent && (
          <div className={css(styles.inlineContent)}>{inlineContent}</div>
        )}
        {isWindowSizeOrMore("large") && useToggle && renderToggle()}
        {additionalContent && (
          <div
            className={css(
              styles.additionalContent,
              showingAdditional && styles.showingAdditionalContent
            )}
          >
            {additionalContent}
          </div>
        )}
      </LazyLoadComponent>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  onTabChange: PropTypes.func,
  inlineContent: PropTypes.node,
  count: PropTypes.node,
  showBorder: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  noInlineMargin: PropTypes.bool,
  TitleComponent: PropTypes.string,
  hiddenTitle: PropTypes.string,
  button: PropTypes.node,
  toggleLabel: PropTypes.node,
  toggledLabel: PropTypes.node,
  toggleTitle: PropTypes.node,
  toggledTitle: PropTypes.node,
  inSidebar: PropTypes.bool,
  additionalContent: PropTypes.node,
  inlineToggleIcon: PropTypes.object,
  useToggle: PropTypes.bool,
  viewAllText: PropTypes.string,
  viewAllTitle: PropTypes.string,
  viewAllDataId: PropTypes.string,
  hideViewAll: PropTypes.bool,
  link: PropTypes.string,
  primary: PropTypes.bool,
  titleId: PropTypes.string,
  renderOnMount: PropTypes.bool,
  passedRef: PropTypes.object,
  showViewAllRightIcon: PropTypes.bool,
};
Title.defaultProps = {
  children: null,
  onTabChange: null,
  passedRef: null,
  inlineContent: null,
  count: null,
  showBorder: false,
  noInlineMargin: false,
  noTopPadding: false,
  TitleComponent: "div",
  hiddenTitle: undefined,
  button: null,
  toggleTitle: undefined,
  toggledTitle: undefined,
  toggleLabel: undefined,
  toggledLabel: undefined,
  inSidebar: false,
  additionalContent: null,
  inlineToggleIcon: null,
  useToggle: false,
  viewAllText: null,
  viewAllDataId: "title-view-all",
  hideViewAll: false,
  link: null,
  primary: false,
  titleId: null,
  renderOnMount: false,
  showViewAllRightIcon: false,
};

export default memo(Title);
