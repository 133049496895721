import PropTypes from "prop-types";

import CTABox from "../../CTAs/CTABox";
import PageSidebarPanel from "./PageSidebarPanel";

import { useLoggedIn } from "hooks/useLoggedInUser";

function PageCTABox(props) {
  const { sidebarPanelProps, children, index, ...rest } = props;
  const isLoggedIn = useLoggedIn();

  return (
    !isLoggedIn && (
      <PageSidebarPanel minimal {...sidebarPanelProps} index={index}>
        <CTABox {...rest}>{children}</CTABox>
      </PageSidebarPanel>
    )
  );
}

PageCTABox.propTypes = {
  sidebarPanelProps: PropTypes.object,
  children: PropTypes.node,
  index: PropTypes.number,
};

PageCTABox.defaultProps = {
  sidebarPanelProps: null,
  children: null,
  index: 0,
};

export default PageCTABox;
