import { List } from "immutable";

import { selectSpecificPodcast } from "selectors/podcast";

import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";

const isPodcastOwnerFromSettings = (podcast, user) =>
  podcast.getIn(["settings", "owners"]) &&
  podcast
    .getIn(["settings", "owners"])
    .filter((o) => o.get("id") === user.get("id")).size === 1;

const isPodcastOwner = (podcast, user) =>
  !!podcast.get("owner_ids", List()).find((id) => id === user.get("id"));

export default function useUserIsPodcastOwner(podcast_id) {
  const user = useLoggedInUser();
  const podcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcast_id),
    [podcast_id]
  );

  if (!user || !podcast) {
    return false;
  }

  return (
    isPodcastOwner(podcast, user) || isPodcastOwnerFromSettings(podcast, user)
  );
}
