import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const WhiteBtnWithIcon = Loadable(() => import("./WhiteBtnWithIcon"));

function ButtonAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const WhiteBtnWithIconContainer = (props) => {
  return <WhiteBtnWithIcon {...props} fallback={<ButtonAsyncLoading />} />;
};

export default WhiteBtnWithIconContainer;
